import React, { useEffect, useState } from 'react';
import { ReactSortable } from "react-sortablejs";
import { makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles((theme) => ({
    dustbin: {
        display: 'none',
        border: '1px solid',
        borderColor: red[900],
        backgroundColor: red[100],
        borderRadius: theme.spacing(0.5),
        '& *': {
            color: red[900],
            flex: 0
        },
        '& .icon': {
            textAlign: 'center',
        },
        '& .zone': {
            backgroundColor: red[50],
            minHeight: '4rem',
            borderColor: red[100],
            border: '0.5rem solid'
        }
    },
}));

const MultipleFormItem = ({id, values, AddItem, Item, valuesHandler, errorsHandler}) => {
    const classes = useStyles();
    const baseId = id?id:Math.random().toString(36).replace(/[^a-z]+/g, '');
    const instanceId = baseId + '-' + Math.random().toString(36).replace(/[^a-z]+/g, '');
    const [formData, _setFormData] = useState([])
    useEffect(() => {
        let dataWithKey = []
        for (let i=0; i<values.length; i++) {
            dataWithKey[i] = {
                value: values[i],
                key: baseId + '-' + Math.random().toString(36).replace(/[^a-z]+/g, ''),
                error: false
            }
         }
        setFormData(dataWithKey)
    // only on mount, so disable warning.
    },[]) // eslint-disable-line react-hooks/exhaustive-deps
    const setFormData = (nfd) => {
        _setFormData(nfd);
        if (typeof valuesHandler !== 'function') return;
        valuesHandler(nfd.reduce((a, c) => {
          a.push(c.value)
          return a;
        }, []))
    }
    const callbackHandler = (index, incomingItemData) => {
        let replaceData = [...formData];
        if (!replaceData[index]) replaceData[index] = {key: baseId + '-' + Math.random().toString(36).replace(/[^a-z]+/g, '')}
        replaceData[index].value = incomingItemData;
        setFormData(replaceData);
    }
    const errorHandler = (index, hasError) => {
        let replaceData = [...formData];
        replaceData[index].error = hasError;
        setFormData(replaceData);
        const newErrorValue = Boolean(replaceData.reduce((a, c) => {if (c.error) {a++} return a}, 0))
        if (typeof errorsHandler === 'function') errorsHandler(newErrorValue)
    }
    const addItemHandler = (e) => {
        e.preventDefault();
        callbackHandler(formData.length)
    };
    // dustbin show and hide by plain JS, to avoid rerenders.
    const showDustBin = () => {
        document.getElementById(instanceId).querySelector('.MultipleFormItemWrapperDustBin').style.display='block'
    }
    const hideDustBin = () => {
        document.getElementById(instanceId).querySelector('.MultipleFormItemWrapperDustBin').style.display='none'
    }
    return (
        <div className="MultipleFormItemWrapper" id={instanceId}>
        <ReactSortable className="MultipleFormItemWrapperList" list={formData} setList={setFormData} group={{name: baseId + "List", pull: true}} onStart={showDustBin} onEnd={hideDustBin}>
        {formData.map((itemData, index) => {return (
            <Item key={itemData.key} value={itemData.value} index={index} valueHandler={incomingItemData => callbackHandler(index, incomingItemData)} errorHandler={err => errorHandler(index, err)}/>
        )})}
        </ReactSortable>
        <div className={`MultipleFormItemWrapperDustBin ${classes.dustbin}`}>
            <div className="icon"><DeleteForeverIcon /></div>
            <ReactSortable className="zone" list={[]} setList={() => {}} group={{name: baseId + "ListRemove", put: true}}></ReactSortable>
        </div>
        {AddItem && <AddItem callback={addItemHandler}/> }
        </div>
    )
}
export default MultipleFormItem;
