import React, { useState, useEffect } from 'react';
import useStyles from './LoginFrom.styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Alert from '@material-ui/lab/Alert';
import WebsocketOnlineIndicator from '../Components/WebsocketOnlineIndicator';

const LoginForm = props => {
    const classes = useStyles();
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [fv, setFormValues] = useState({error: {state: false, help: ''}, name: {value: '', error: false, help: ''}, password: {value: '', error: false, help: ''}});
    useEffect(() => {
        const newButtonDisabled = fv.error.state || (fv.name.value === '' || fv.password.value === '');
        setButtonDisabled(newButtonDisabled)
    }, [fv.name.value, fv.password.value, fv.error.state])
    useEffect(() => {
        document.body.classList.add('login-form');
        return(() => {
            document.body.classList.remove('login-form');
        })
    })
    const onFormValuesChange = e => {
        let nv = { ...fv };
        nv[e.target.id].value = e.target.value
        if(e.target.required && nv[e.target.id].value.length && nv[e.target.id].value.trim() === '') {
            nv[e.target.id].error = true;
            nv[e.target.id].help = e.target.labels[0].firstChild.nodeValue + ' is required.';
        } else {
            nv[e.target.id].error = false;
            nv[e.target.id].help = ''; 
        }
        nv.error.state = (nv.name.error || nv.password.error);
        nv.error.help = 'Please notice the errors.'
        setFormValues(nv);
    }
    const onFormSubmit = (e) => {
        e.preventDefault();
        if (fv.error.state) return false;
        const axios = new window.axiosToApi();
        axios.post(
            '/api/login', 
            {name: fv.name.value, password: fv.password.value},
            {withCredentials: true}
        ).then( res =>{
            window.newJwtToken(res.data);
        }, e => {
            let nv = { ...fv };
            nv.error.state = true;
            nv.error.help = 'Login failed.'
            nv.password.value = '';
            setFormValues(nv);
        });        
    }

    return (
        <Box
            className={classes.root}
            >
            <CssBaseline />
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.grid}
                >
                <Paper
                    className={classes.paper}
                    >
                    <AppBar
                        position="relative"
                        className={classes.appbar}
                        >
                        <Toolbar>
                            <Typography variant="h6" noWrap>
                                Login
                            </Typography>
                            <div className={classes.grow} />
                            <WebsocketOnlineIndicator />
                        </Toolbar>
                    </AppBar>
                    <form
                        className={classes.form}
                        noValidate
                        autoComplete="off"
                        onSubmit={onFormSubmit}
                        >
                        {fv.error.state && <Alert severity="error">{fv.error.help}</Alert>}
                        <Box
                            className={classes.fitem}
                            >
                            <TextField
                                helperText={fv.name.help}
                                error={fv.name.error}
                                value={fv.name.value}
                                onChange={onFormValuesChange}
                                fullWidth
                                required
                                id="name"
                                label="Username"
                                variant="outlined"
                            />
                        </Box>
                        <Box
                            className={classes.fitem}
                            >
                            <TextField
                                helperText={fv.password.help}
                                error={fv.password.error}
                                value={fv.password.value}
                                onChange={onFormValuesChange}
                                fullWidth
                                required
                                id="password"
                                label="Password"
                                variant="outlined"
                                type="password"
                                autoComplete="current-password"
                            />
                        </Box>
                        <Box
                            className={classes.fitem}
                            >
                            <Button
                                disabled={buttonDisabled}
                                fullWidth
                                variant="contained"
                                color="primary"
                                type="submit"
                                className={classes.button}
                                endIcon={<LockOpenIcon />}
                            >Login
                            </Button>
                        </Box>
                    </form>
                </Paper>
            </Grid>
        </Box>
    );
}

export default LoginForm;