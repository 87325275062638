import React from "react";
import {
    Switch,
    Route,
    useRouteMatch
  } from "react-router-dom";
import Events from './Events'

const Settings = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <h3>Settings.</h3>
      </Route>
      <Route path={`${path}/events`}>
        <Events />
      </Route>
    </Switch>
  );
}
export default Settings