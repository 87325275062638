import React, { useState, useEffect } from 'react';
import './WebsocketOnlineIndicator.css';

const WebsocketOnlineIndicator = props => { 
    const [status, setStatus] = useState(window.wsc.status);
    useEffect(() => {
        const listener = window.emitter.on('websocket.status.*', function() {
            setStatus(window.wsc.status);
        }, {objectify: true});
        return () => {
            listener.off();
        };
    });
    
    return (
        <span className={`OnlineIndicator ${status}`} />
    );
}
export default WebsocketOnlineIndicator;