import React from "react";
import {
  Switch,
  Route
} from "react-router-dom";
import BuildingOverview from './BuildingOverview'
import Settings from './Settings';

const MainRouting = () => {
  return (
        <Switch>
          <Route exact path="/">
            <BuildingOverview />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
        </Switch>
  );
}
export default MainRouting
