import React, { useState, useEffect, useCallback, useRef } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReplayIcon from '@material-ui/icons/Replay';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import MultipleFormItem from '../../../Components/MultipleFormItem'

const Events = () => {
    const request_at_once = 10;
    const [events, setEvents] = useState({});
    const [page, setPage] = useState(0);
    const [has_more, setHasMore] = useState(true);
    const [is_loading, setIsLoading] = useState(false);
    const [query_for, setQueryFor] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [activeEventData, setActiveEventData] = useState({name: '', data: []});
    
    const openFireEventForm = useCallback((evd) => {
        setActiveEventData(evd)
        setOpenDialog(true)
    }, []);


    const getEventsFromApi = useCallback(() => {
        setIsLoading(true);
        const skip = (page * request_at_once);

        let search = {};
        if (query_for.length) {
            let match = '^' + 
                        query_for
                        .replaceAll('.', '\\.')
                        .replaceAll('**', '[a-zA-Z0-9\\.]+')
                        .replaceAll('*', '[a-zA-Z0-9]+')
                        + '$'
            search = {query: {name: {$regex: match}}}
        }
        const axios = new window.axiosToApi();
        axios.get(
            '/api/events',
            {params: {
                limit: (request_at_once + 1),
                sort: {'_id': -1},
                skip: skip,
                ...search,
            }
        }).then(res => {
            let recvd = res.data
            if (recvd.length <= request_at_once) setHasMore(false);
            else { recvd.pop(); setHasMore(true); }
            let obj = {}
            for (let i=0; i<recvd.length; i++) {
                obj[recvd[i]._id] = recvd[i];
            }
            setIsLoading(false);

            if (page > 0) {
                setEvents(e => {return {...e, ...obj}});
            }
            else
                setEvents(obj);
        }, e => {
            setIsLoading(false);
            console.log(e);
        });
    }, [page, query_for]);
    useEffect(() => {
        getEventsFromApi()
    }, [getEventsFromApi])
    useTriggerScrollFix([events, is_loading, has_more])
    const loadMore = () => {
        setPage(p => (p+1))
    }
    const refresh = (search_str) => {
        setQueryFor(search_str)
        setPage(0);
    }
    return (
        <>
        <h1>Events</h1>
        <FireEventDialog setOpenDialog={setOpenDialog} openDialog={openDialog} activeEventData={activeEventData} />
        <SelectForm callback={refresh} />
        <List events={Object.values(events)} load_more={loadMore} has_more={has_more} is_loading={is_loading} openFireEventForm={openFireEventForm} />
        </>
    );
}
export default Events;


const useFormStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    input: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    button: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    }
}));

const SelectForm = React.memo(({ callback }) => {
    const classes = useFormStyles();
    const [options, setOptions] = useState([]);
    const [fv, setFormValues] = useState({'search-event-name': {value: ''}})
    const loadOptions = useCallback(() => {
        const axios = new window.axiosToApi();
        axios.get(     
            '/api/events',
            {params: {distinct:'name', sort: {'name': 1}}}
        ).then(res => {
            setOptions(o => res.data)
        }, e => {
            console.log(e);
        });
    }, [])
    useEffect(() => loadOptions(), [loadOptions])
    const onFormValuesChange = e => {
        let nv = { ...fv };
        if (e.target.tagName === 'INPUT')
            nv['search-event-name'].value = e.target.value
        else
            nv['search-event-name'].value = e.target.textContent
        setFormValues(nv);
    }
    const onFormSubmit = (e) => {
        e.preventDefault();
        callback(fv['search-event-name'].value)    
    }
    return (
        <form
        noValidate
        autoComplete="off"
        onSubmit={onFormSubmit}
        className={classes.root}
        >
        <Autocomplete
          id="search-event-name"
          className={classes.input}
          freeSolo
          options={options}
          onInputChange={onFormValuesChange}
          renderInput={(params) => (
            <TextField 
              {...params} 
              value={fv['search-event-name'].value}
              placeholder="Filter on event name"
              margin="normal"
              variant="outlined"
            />
          )}
        />
        <Button className={classes.button} type="submit" variant="contained" color="primary" disableElevation>Filter</Button>
        </form>
    )
});

const List = React.memo(({ events, load_more, has_more, is_loading, openFireEventForm }) => {
    let date_str = '';
    const listLength = events.length
    return (
        <InfiniteScroll
        dataLength={listLength}
        next={load_more}
        hasMore={has_more && !is_loading}
        >
        <Timeline>
        {events.map((item, i) => {
            let date_obj = null;
            const last = (listLength === (i+1))
            const date = new Date(item.created_at * 1000);
            const this_date_str = ('0' + date.getDate()).slice(-2) + ' - '
                                + ('0' + (date.getMonth()+1)).slice(-2) + ' - '
                                + date.getFullYear();
            if (this_date_str !== date_str) {
                date_str = this_date_str;
                date_obj = {name: date_str}
            }
            return (
                <React.Fragment key={item._id.toString()}>
                {date_obj && <ListDateItem date={date_str} />}
                <ListItem item={item} last={last} openFireEventForm={openFireEventForm} />
                </React.Fragment>
        )})}
        </Timeline>
        </InfiniteScroll>
    );
});
const useTimeLineStyles = makeStyles((theme) => ({
    firstcolumn: {
        flex: '1 8 0',
        flexWrap: 'nowrap',
    },
    vcenter: {
        marginTop: (0-theme.spacing(1)),
    },
    secondcolumn: {
        flex: '8 1 0',
    },
    vcenter2: {
        marginTop: (0-theme.spacing(0.5)),
    },
    event: {
        flex: 1,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        '& div': {
            flex: 1,
            
        },
        '& .arg': {
            display: 'flex',
            flexDirection: 'row',
            overflow: 'hidden',
            cursor: 'pointer',
            '& *': {
                flex: '0 1 auto',
                display: 'flex',
                minWidth: 'min-content',
                alignContent: 'left',
                
            },
            '& pre': {
                margin: 0,
                overflow: 'hidden',
                maxWidth: 'min-content',
                whiteSpace: 'pre-wrap',
                overflowWrap: 'break-word',
                color: theme.palette.text.secondary,
            },
            '& pre.close': {
                maxHeight: theme.spacing(9),
            },
            '& pre.open': {
                whiteSpace: 'pre',
                color: theme.palette.text.primary,
            }
        },

    },
    blackdot: {
        borderColor: '#000',
        backgroundColor: '#000',
    },
    grow: {
        flexGrow: 1,
    },
}));

const ListItem = React.memo(({ item, last, openFireEventForm }) => {
    const [opened, setOpened] = useState(false);
    const classes = useTimeLineStyles();
    const date = new Date(item.created_at * 1000);
    const time = ('0' + date.getHours()).slice(-2) + ':'
               + ('0' + date.getMinutes()).slice(-2) + ':'
               + ('0' + date.getSeconds()).slice(-2);
    const handleOpen = () => {
        setOpened(!opened);
    }
    const handleButton = () => {
        openFireEventForm({name: item.name, data: item.data})
    }
    return (
      <TimelineItem>
        <TimelineOppositeContent className={classes.firstcolumn}>
            <Typography color="textSecondary" className={classes.vcenter2}>{time}</Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="default" />
          {!last && <TimelineConnector /> }
        </TimelineSeparator>
        <TimelineContent className={classes.secondcolumn}>
            <Paper>
                <Toolbar><Typography className={classes.grow}><b>{item.name}</b></Typography><Button onClick={handleButton} startIcon={<ReplayIcon />}>Replay</Button></Toolbar>
                <Box component="div" className={classes.event}>
                    {item.data.map((arg_data, i) => {
                        const key = (item._id + '.arg.' + i).toString();
                        const json = JSON.stringify(arg_data, null, 1);
                        const json_display = opened?json:json.substring(0, 320);
                        return(
                        <Box key={key} component="div" className="arg" onClick={handleOpen}>
                            <Typography color="textPrimary">Arg #{(i+1)}:&nbsp;</Typography>
                            <Box  color="textSecondary" component="pre" className={opened?'open':'close'}>
                                {json_display}
                            </Box>
                        </Box>
                        )
                    })}
                </Box>
            </Paper>
        </TimelineContent>
      </TimelineItem>
    )
});
const ListDateItem = React.memo(({ date }) => {
    const classes = useTimeLineStyles();
    return (
      <TimelineItem>
        <TimelineOppositeContent className={classes.firstcolumn}>
            <Typography variant="h6" className={classes.vcenter}>{date}</Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="default" className={classes.blackdot}/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className={classes.secondcolumn}>&nbsp;</TimelineContent>
      </TimelineItem>
    )
});
const useTriggerScrollFix = (deps) => {
    if (!Array.isArray(deps)) deps = [deps]
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.dispatchEvent(new CustomEvent('scroll'));
        }
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}

const useDialogStyles = makeStyles((theme) => ({
    argfield: {
        cursor: 'grab',
    },
    formwrap: {
        display: 'flex',
        flexDirection: 'column',
        '& *': {
            flex: 1,
        },
        '& input': {
            width: '600rem'
        },
        '& textarea': {
            whiteSpace: 'pre',
        },
        '& .MultipleFormItemWrapperList':{
            display: 'flex',
            flexDirection: 'column',
        },
    },
}));
const ArgField = ({ index, value, errorHandler, valueHandler }) => {
    const classes = useDialogStyles();
    const [val, setVal] = useState(value)
    const [error, setError] = useState(false)
    const [helperText, setHelperText] = useState(typeof value)
    const handleChange = (e) => {
      e.preventDefault();
      const iv = e.target.value.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
      let sv = '';
      let returnError = false;
      try {
        sv = iv?JSON.parse(iv):'';
        if (typeof sv === 'object' && iv === 'null' && sv === null) {
            setHelperText('null');
        }
        else if (typeof sv === 'object' && iv.charAt(0) === '[') {
            setHelperText('array');
        }
        else {
            setHelperText(typeof sv)
        }
        setVal(sv)
        valueHandler(sv)
      } catch (err) {
        returnError = true;
        setVal(e.target.value)
      }
      setError(returnError)
      errorHandler(returnError)
    }
    return (
        <TextField
            id={"arg"+index}
            value={val||val===null||val===0?error?val:JSON.stringify(val, null, 4):''}
            label={"Arg #" + (index+1)}
            className={classes.argfield}
            multiline
            margin="normal"
            variant="outlined"
            onChange={handleChange}
            error={error}
            helperText={error?'The argument must be JSON encoded.':helperText}
        />
    )
}
const FireEventDialog = ({activeEventData, openDialog, setOpenDialog}) => {
    const classes = useDialogStyles();
    //const [open, setOpen] = useState(false);
    const emptyEventData = {name: '', data: []};
    const [eventData, setEventData] = useState(emptyEventData);
    useEffect(() => {
        setEventData(activeEventData)
    }, [activeEventData])
    const handleOpen = () => {
        setEventData(emptyEventData)
        setOpenDialog(true);
    };
  
    const handleClose = () => {
        setEventData(emptyEventData)
        setOpenDialog(false);
    };
  
    const nameElementRef = useRef(null);
    useEffect(() => {
      if (openDialog) {
        const { current: nameElement } = nameElementRef;
        if (nameElement !== null) {
            nameElement.focus();
        }
      }
    }, [openDialog]);
    const onFormSubmit = (e) => {
        e.preventDefault();
        setOpenDialog(false);
    }
    const handleNameChange = (e) => {
        setEventData({...eventData, name: e.target.value})
    }
    const handleDataChange = (values) => {
        console.log(values)
        setEventData({...eventData, data: values})
    }
    useEffect(() => {
        console.log(eventData)
    }, [eventData])
  
    return (
      <div>
        <Button onClick={handleOpen}>Fire new event</Button>
        <Dialog
          open={openDialog}
          onClose={handleClose}
          scroll='paper'
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="xl"
        >
          <form
            noValidate
            autoComplete="off"
            onSubmit={onFormSubmit}
          >
          <DialogTitle id="scroll-dialog-title">Fire event</DialogTitle>
          <DialogContent dividers tabIndex={-1} className={classes.formwrap}>
                <TextField
                  id="name"
                  ref={nameElementRef}
                  value={eventData.name}
                  label="Event name"
                  margin="normal"
                  variant="outlined"
                  onChange={handleNameChange}
                />
                <MultipleFormItem
                    id='data'
                    values={eventData.data}
                    Item={ArgField}
                    AddItem={({callback}) => (<Button onClick={callback} >Add Argument</Button>)}
                    valuesHandler={handleDataChange}
                />
           </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" onClick={onFormSubmit}  startIcon={<PlayCircleOutlineIcon />} color="primary">
              Fire
            </Button>
          </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }