import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import TvIcon from '@material-ui/icons/Tv';
import DnsIcon from '@material-ui/icons/Dns';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import RouterIcon from '@material-ui/icons/Router';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import SimCardIcon from '@material-ui/icons/SimCard';
import CompassCalibrationIcon from '@material-ui/icons/CompassCalibration';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useSearchStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
  }));

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});
const useOverviewStyles = makeStyles((theme) => ({
  bar: {
    display: 'flex',
    marginBottom: theme.spacing(2)
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));
const useGetTypeStyles = makeStyles((theme) => ({
  formControl: {
    backgroundColor: theme.palette.background.paper,
    marginRight: theme.spacing(2)
  },
  placeholder: {
    margin:0,
    padding: 0,
  }
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function SearchInput(props) {
    const [inputValue, setInputValue] = useState({target: {value:''}});
    const classes = useSearchStyles();
    const submitHandler = (e) => {
      e.preventDefault();
      props.fireSearch(inputValue.target.value)
    }

    return (
      <Paper component="form" className={classes.root} onSubmit={submitHandler}>
        <InputBase
          className={classes.input}
          placeholder="Search"
          inputProps={{ 'aria-label': 'search' }}
          value={inputValue.target.value}
          onChange={setInputValue}
        />
        <IconButton type="submit" className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
    );
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const hardwareTypes = {
  '0120Y000000ylRnQAI': 'Screen',
  '0120Y000000ysnOQAQ': 'Mediaplayer',
  '0120Y000000ylRxQAI': 'Router',
  // '0121n00000033AkAAI': 'Sensor',
  '0120Y000000ylS2QAI': 'Simcard',
  // '0121n00000033AuAAI': 'iPad',
}
function TypeSelect(props) {
  const classes = useGetTypeStyles();
  const theme = useTheme();
  const [types, setType] = useState([]);
  const handleChange = (event) => {
    setType(event.target.value);
    props.callBack(event.target.value);
  };
  return (
    <FormControl variant="outlined" className={classes.formControl}>
    <Select
      className={classes.select}
      multiple
      displayEmpty
      value={types}
      onChange={handleChange}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return (<MenuItem disabled value="" className={classes.placeholder}>
          Type
        </MenuItem>);
        }
        return selected.map( (id) => (hardwareTypes[id])).join(', ')
      }}
      MenuProps={MenuProps}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      <MenuItem disabled value="">
        <em>Type</em>
      </MenuItem>
      {Object.keys(hardwareTypes).map((id) => (
        <MenuItem key={id} value={id} style={getStyles(id, types, theme)}>
          {hardwareIcon(id)}<span>&nbsp;</span>{hardwareTypes[id]}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
  );
}
const statusses = {
  '-1': 'None',
  '0': 'Unkown',
  '1': 'Ok',
  '2': 'Error',
}
function StatusSelect(props) {
  const classes = useGetTypeStyles();
  const theme = useTheme();
  const [status, setStatus] = useState([]);
  const handleChange = (event) => {
    setStatus(event.target.value);
    props.callBack(event.target.value);
  };
  return (
    <FormControl variant="outlined" className={classes.formControl}>
    <Select
      className={classes.select}
      multiple
      displayEmpty
      value={status}
      onChange={handleChange}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return (<MenuItem disabled value="" className={classes.placeholder}>
          Status
        </MenuItem>);
        }
        return selected.map( (id) => (statusses[id])).join(', ')
      }}
      MenuProps={MenuProps}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      <MenuItem disabled value="">
        <em>Status</em>
      </MenuItem>
      {Object.keys(statusses).map((id) => (
        <MenuItem key={id} value={id} style={getStyles(id, status, theme)}>
          {statusIcon(id)}<span>&nbsp;</span>{statusses[id]}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
  );
}
/*
 0120Y000000ylRnQAI: Screen
 0120Y000000ysnOQAQ: Mediaplayer
 0120Y000000ylRxQAI: Router
 0121n00000033AkAAI: Sensor
 0120Y000000ylS2QAI: Simcard
 0121n00000033AuAAI: iPad
*/
function hardwareIcon(type) {
  if (type === '0120Y000000ysnOQAQ') return (<DnsIcon fontSize="small" />);
  if (type === '0120Y000000ylRnQAI') return (<TvIcon fontSize="small" />);
  if (type === '0120Y000000ylRxQAI') return (<RouterIcon fontSize="small" />);
  if (type === '0121n00000033AuAAI') return (<TabletMacIcon fontSize="small" />);
  if (type === '0120Y000000ylS2QAI') return (<SimCardIcon fontSize="small" />);
  if (type === '0121n00000033AkAAI') return (<CompassCalibrationIcon fontSize="small" />);
  return (<SettingsInputComponentIcon />);
}
function statusIcon(state) {
  if (state === '0') return (<HelpOutlineIcon color="disabled" />)
  if (state === '1') return (<CheckIcon style={{color: 'rgb(0, 140, 0)'}} />)
  if (state === '2') return (<ErrorOutlineIcon color="error" />)
  return '';
}

function BuildingRow(props) {
    const { building, handleOpenDialog} = props;
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();
    return (
        <React.Fragment key={building._id}>
          <TableRow className={classes.root}>
            <TableCell>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell component="th" scope="row" onClick={handleOpenDialog('building', building.sfid)}>
                {building.parent?building.parent.name + ' ':''}
                {building.name}
            </TableCell>
            <TableCell align="right">
                {building.statussen['0'] > 0 ? statusIcon('0') : ''}
                {building.statussen['1'] > 0 ? statusIcon('1') : ''}
                {building.statussen['2'] > 0 ? statusIcon('2') : ''}
            </TableCell>
          </TableRow>
          <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
              <Table aria-label="collapsible table">
                <TableBody>
                {building.hardware.map((hw) => (
                    <TableRow key={hw._id} onClick={handleOpenDialog('hardware', hw.sfid)}>
                        <TableCell>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}>
                            {hardwareIcon(hw.data.RecordTypeId)}
                            &nbsp;
                            {hw.name}
                            </div>
                            </TableCell>
                        <TableCell align="right">
                          {statusIcon(hw.status)}
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
              </Table>
              </Box>
            </Collapse>
          </TableCell>
          </TableRow>
        </React.Fragment>
    );
}
const useDialogStyles = makeStyles((theme) => ({
  pre: {
    whiteSpace: 'pre-wrap',
    border: 0,
  },
}));
function ObjectDialogContent(props) {
  const obj = props.object;
  const type = props.type;
  const classes = useDialogStyles();
  if (type === 'building') {
    return (
      <React.Fragment>
        <div>
        <h5>Adres</h5>
        {obj.data.Street__c} {obj.data.Number__c}<br />
        {obj.data.Postalcode__c} {obj.data.City__c}<br />
        {obj.data.Country__c}<br />
        <hr />
        <h5>Comments</h5>
        <Box className={classes.pre}>
        {obj.data.opmerkingen_general__c}
        </Box>
        <hr />
        <h5>Installatie</h5>
        <Box className={classes.pre}>
        {obj.data.Opmerkingen_installation__c}
        </Box>
        {obj.data.Internet__c}<br />
        <Box className={classes.pre}>{obj.data.Internet_details__c}</Box>
        {obj.data.Opleverbon__c}
        <hr />
        </div>
      </React.Fragment>
    )
  }
  return (
    type
  )
}
function RouterButton(props) {
  const { type, object } = props;
  if (type === 'building') return null;
  if (object.data.RecordTypeId !== '0120Y000000ylRxQAI') return null;
  if (object.status !== '1' && object.status !== '2') return null;
  const handleClick = () => {
    let uri = 'https://rms.teltonika-networks.com/management/devices?q=' + object.name + '&search-fields=serial';
    window.open(uri, 'SalesForce');
  }
  return (
    <Button onClick={handleClick}>Teltonika</Button>
  )
}
function SimButton(props) {
  const { type, object } = props;
  if (type === 'building') return null;
  if (object.data.RecordTypeId !== '0120Y000000ylS2QAI') return null;
  if (object.status !== '1' && object.status !== '2') return null;
  const handleClick = () => {
    let uri = 'https://kpn.jasperwireless.com/provision/ui/terminals/sims/sims.html?iccid=' + object.name;
    window.open(uri, 'SalesForce');
  }
  return (
    <Button onClick={handleClick}>KPN</Button>
  )
}
function NarrowscapeButton(props) {
  const { type, object } = props;
  if (type === 'building') return null;
  if (object.data.RecordTypeId !== '0120Y000000ysnOQAQ') return null;
  if (object.status !== '1' && object.status !== '2') return null;
  const handleClick = () => {
    let uri = 'https://cms.hello-energy.com/nl-he/admin/narrowscape_player/' + object.data.Monitoring_ID__c;
    window.open(uri, 'SalesForce');
  }
  return (
    <Button onClick={handleClick}>CMS</Button>
  )
}

function CollapsibleTable() {
    const [searchFor, setSearchFor] = useState('');
    const [filterState, setFilterState] = useState([]);
    const [filterType, setFilterType] = useState([]);
    const [buildings, setBuildings] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [activeType, setActiveType] = useState('building');
    const [activeId, setActiveId] = useState(null);
    const [activeObject, setActiveObject] = useState({name: '', data: {}});
    const [hardware, setHardware] = useState({});
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('status');
    const descriptionElementRef = useRef(null);
    const classes = useOverviewStyles();

    useEffect(() => {
        if (openDialog) {
          const { current: descriptionElement } = descriptionElementRef;
          if (descriptionElement !== null) {
            descriptionElement.focus();
          }
        }
    }, [openDialog]);

    useEffect(() => {
      setBuildings(buildingSort(buildings));
    }, [order, orderBy]);

    const buildingSort = (buildingsObj) => {
      let buildingsArr = Object.values(buildingsObj).map((building) => {
        building.sortkey = ((building.parent?building.parent.name+' ':'') + building.name).toUpperCase();
        building.statusStr = '';
        if (orderBy === 'status') {
          building.statusStr = '';
          building.statusStr += ('000' + (building.statussen[2])).substr(-3) + '.';
          building.statusStr += ('000' + (building.statussen[0])).substr(-3) + '.';
          building.statusStr += ('000' + (building.statussen[1])).substr(-3);
        }
        return building;
      });
      if (orderBy !== 'status') {
        buildingsArr.sort((a, b) => {
          if (a.sortkey < b.sortkey) return order === 'asc'?-1:1;
          if (a.sortkey > b.sortkey) return order === 'desc'?-1:1;
          return 0;
        })
      } else {
        //first alpha betical on name
        buildingsArr.sort((a, b) => {
          if (a.sortkey < b.sortkey) return -1;
          if (a.sortkey > b.sortkey) return 1;
          return 0;
        })
        buildingsArr.sort((a, b) => {
          if (a.statusStr > b.statusStr) return order === 'asc'?-1:1;
          if (a.statusStr < b.statusStr) return order === 'desc'?-1:1;
          return 0;
        })
      }
      let ret = {};
      buildingsArr.forEach(b => {
        ret[b.sfid] = b;
      })
      return ret;
    }

    useEffect(() => {
      async function fetchApiData () {
        const axios = new window.axiosToApi();

        let query = {
          'buildingid':{'$ne': null},
          'buildingid':{'$ne': ''},
          'data.in_use__c': {'$eq': true},
          'data.IsDeleted': {'$eq': false},
          'insfsync': {'$eq': '1'},
          'data.RecordTypeId': {'$nin': ['0121n00000033AkAAI', '0121n00000033AuAAI']},
        }
        if (filterType.length) {
          query['data.RecordTypeId'] = {'$in': filterType}
        }
        if (filterState.length) {
          query['status'] = {'$in': filterState}
        }
        if (searchFor) {
          query['$or'] = [{'name': {'$regex': searchFor, '$options': 'i'}}]
          let searchForBuildingIds = [];
          let parentBuildings = [];
          let allBuildings = [];
          await axios.get(
            '/api/sf_hardwares',
            {params: {
              'select': 'buildingid',
              'distinct': 'buildingid',
              'query': {
                'buildingid':{'$ne': null},
                'data.in_use__c': {'$eq': true},
                'data.IsDeleted': {'$eq': false},
                'insfsync': {'$eq': '1'},
              }
            }},
            {withCredentials: true}
          ).then( res =>{
            allBuildings = res.data;
          })
          await axios.get(
            '/api/sf_buildings',
            {params: {
              'select': 'sfid',
              'query': {
                'name': {'$regex': searchFor, '$options': 'i'},
                'p_sfid':{'$eq': null},
                'insfsync': {'$eq': '1'},
              }
            }},
            {withCredentials: true}
          ).then( res =>{
            res.data.forEach(b => {
              parentBuildings.push(b.sfid)
            })
          })
          await axios.get(
            '/api/sf_buildings',
            {params: {
              'select': 'sfid',
              'query': {
                'insfsync': {'$eq': '1'},
                '$or' : [
                {'p_sfid': {'$in': parentBuildings}},
                {'name': {'$regex': searchFor, '$options': 'i'}},
                {'data.Postalcode__c': {'$regex': searchFor, '$options': 'i'}},
                {'data.City__c': {'$regex': searchFor, '$options': 'i'}},
              ]},
            }},
            {withCredentials: true}
          ).then( res =>{
            res.data.forEach(b => {
              if (allBuildings.includes(b.sfid)) searchForBuildingIds.push(b.sfid)
            })
            query['$or'].push({'buildingid': {'$in': searchForBuildingIds}})
          })
        }
        axios.get(
            '/api/sf_hardwares',
            {params: {
              'populate': {
                'path': 'building',
                'populate': {'path': 'parent', 'select': 'name'},
              },
              'query': query,
            }},
            {withCredentials: true}
        ).then( res =>{
            let hw = {};
            let bs = {};
            res.data.forEach(h => {
                if(!h || !h.sfid || !h.building) return;
                hw[h.sfid] = h;
                if (!bs[h.buildingid]) {
                  bs[h.buildingid] = h.building;
                  bs[h.buildingid].hardware = [];
                  bs[h.buildingid].statussen = {'0':0, '1':0, '2':0};
                }
                bs[h.buildingid].statussen[h.status]++;
                bs[h.buildingid].hardware.push(h);
            });
            setHardware(hw);
            setBuildings(buildingSort(bs));
        });
      }
      fetchApiData();
    }, [searchFor, filterState, filterType])

    const handleOpenDialog = (type, id) => () => {
        setOpenDialog(true);
        setActiveId(id);
        setActiveType(type);
        if (type === 'hardware') setActiveObject(hardware[id])
        else if (type === 'building') setActiveObject(buildings[id])
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const handleClickSalesforce = () => {
        let uri = '';
        //https://hello-energy.lightning.force.com/lightning/r/Hardware__c/a0T1n00000AWytwEAD/view
        if (activeType === 'hardware') {
            uri = 'https://hello-energy.lightning.force.com/lightning/r/Hardware__c/' + activeId + '/view';
        }
        if (activeType === 'building') {
            uri = 'https://hello-energy.lightning.force.com/lightning/r/Building__c/' + activeId + '/view';
        }

        if (uri) {
            window.open(uri, 'SalesForce');
        }
    }
    const handleTicketSalesforce = () => {
      let bid = '';
      if (activeType === 'hardware') {
        bid = activeObject.buildingid;
      }
      if (activeType === 'building') {
          bid = activeObject.sfid;
      }


      if (bid) {
        const uri = 'https://hello-energy.lightning.force.com/lightning/o/Case/new?count=1&nooverride=1&useRecordTypeCheck=1&navigationLocation=LIST_VIEW&defaultFieldValues=Building__c=' + bid;
        window.open(uri, 'SalesForce');
      }
    }
    return (
        <React.Fragment>
        <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
            {activeObject.parent?activeObject.parent.name +  ' ':''}
            {activeObject.name}
        </DialogTitle>
        <DialogContent>


            <ObjectDialogContent type={activeType} object={activeObject} />

            <Table aria-label="table"><tbody>{
                Object.keys(activeObject.data).map(k => {
                  if (activeObject.data[k]) return(
                    <TableRow key={activeObject._id + '.'+ k}>
                    <TableCell>{k}</TableCell>
                    <TableCell>{activeObject.data[k]?activeObject.data[k].toString():''}</TableCell>
                    </TableRow>
                ); return null})
            }{activeType === 'hardware'?(
                    <TableRow key={activeObject._id + '.statusdata'}>
                    <TableCell>Status</TableCell>
                    <TableCell><pre>{JSON.stringify(activeObject.statusdata, null, 2)}</pre></TableCell>
                    </TableRow>
            ):null
            }</tbody></Table>


        </DialogContent>
        <DialogActions>
          <RouterButton type={activeType} object={activeObject} />
          <SimButton type={activeType} object={activeObject} />
          <NarrowscapeButton type={activeType} object={activeObject} />
          <Button onClick={handleTicketSalesforce}>Create Ticket</Button>
          <Button onClick={handleClickSalesforce}>View in SalesForce</Button>
          <Button onClick={handleCloseDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Box className={classes.bar}>
        <TypeSelect callBack={setFilterType} />
        <StatusSelect callBack={setFilterState} />
        <SearchInput fireSearch={setSearchFor} />
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>

                <TableSortLabel
                  active={orderBy === 'buildingname'}
                  direction={orderBy === 'buildingname' ? order : 'asc'}
                  onClick={(e) => {handleRequestSort(e, 'buildingname')}}
                >
                  Building
                  {orderBy === 'buildingname' ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>

              </TableCell>
              <TableCell align="right">
              <TableSortLabel
                  active={orderBy === 'status'}
                  direction={orderBy === 'status' ? order : 'asc'}
                  onClick={(e) => {handleRequestSort(e, 'status')}}
                >
                  Status
                  {orderBy === 'status' ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>

              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(buildings).map((building) => (
              <BuildingRow key={building._id} building={building} handleOpenDialog={handleOpenDialog} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </React.Fragment>
    );
  }
export default CollapsibleTable
