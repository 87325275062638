import './LoginForm.css';
import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => {
    return {
        root: {
            flexGrow: 1,
            height: '100%',
        },
        grid: {
            height: '100%',
        },
        paper: {
            display: 'flex',
            flexWrap: 'wrap',
            margin: theme.spacing(2),
            color: theme.palette.text.primary,
            flexDirection: 'column',
        },
        form: {
            margin: theme.spacing(2),
            flexGrow: 1,
        },
        button: {
            
        },
        fitem: {
            margin: theme.spacing(2),
        },
        appbar: {
            backgroundColor: '#333',
        },
        grow: {
            flexGrow: 1,
        },
    };
});