function WebSocketClient(options){
  this.status = 'offline';
  this.emitter = options.emitter;
  this.autoReconnectInterval = 5*1000;	// ms
  this.heartbeatInterval = 3*1000;	// ms
  this.externalOn = [];
  this.token = options.token;
}
WebSocketClient.prototype.open = function(url){
	this.url = url;
	this.instance = new WebSocket(this.url);
	this.instance.addEventListener('message', (mesg) =>{
		if (mesg.data === '') return;
		try {
			const data = JSON.parse(mesg.data);
			if (data.type === 'command' && data.command === 'authenticated') {
				let status = 'online';
				if (data.result)
					status = 'authenticated'
				if (status !== this.status) {
					this.status = status
					this.emitter.emit('websocket.status.' + status)
				}
			}
		} catch (error) {
			// no json?
			console.log("WebSocketClient: Received mailformed message: " + JSON.stringify(mesg))
		}
	})
	this.instance.addEventListener('open', (e)=>{
		this.status = 'online';
		this.emitter.emit('websocket.status.online')
		console.log("WebSocketClient: connected to " + e.currentTarget.url );
		this.authenticate(this.token);
		this.heartbeat();
	})
	this.instance.addEventListener('close', (e)=>{
		this.status = 'offline';
        this.emitter.emit('websocket.status.offline')
		switch (e.code){
		case 1000:	// CLOSE_NORMAL
			console.log("WebSocket: closed");
			break;
		default:	// Abnormal closure
			this.reconnect(e);
			break;
		}
	});
	this.instance.addEventListener('error', (e)=>{
		this.status = 'offline';
        this.emitter.emit('websocket.status.offline')
		switch (e.code){
		case 'ECONNREFUSED':
			this.reconnect(e);
			break;
		default:
			break;
		}
	});
  return this
}
WebSocketClient.prototype.authenticate = function(token) {
	this.token = token;
	this.sendObject({
		type: 'command',
		command: 'authenticate',
		token: token,
	})
}
WebSocketClient.prototype.heartbeat = function() {
  if (!this.instance) return;
  if (this.instance.readyState !== 1) return;
  this.instance.send('hb');
  var that = this;
  this.instance.heartbeatTimer = setTimeout(function(){
    that.heartbeat()
  }, this.heartbeatInterval);
}
WebSocketClient.prototype.send = function (data, option) {
  // Todo: build in buffer when client is offline (has no instance).
	try {
		this.instance.send(data, option);
	} catch (e) {
		console.log('Websocket: error');
		console.log(e);
	}
}
WebSocketClient.prototype.sendObject = function (obj, option) {
	this.send(JSON.stringify(obj), option)
}
WebSocketClient.prototype.reconnect = function(e){
	console.log(`WebSocketClient: retry in ${this.autoReconnectInterval}ms`, e);
  if (this.instance.heartbeatTimer) {
    clearTimeout(this.instance.heartbeatTimer);
  }
  delete this.instance;
	var that = this;
	setTimeout(function(){
		console.log("WebSocketClient: reconnecting...");
		that.open(that.url);
    	that.externalOn.forEach(function (eventListner) {
      	console.log("WebSocketClient: applying listner: " + eventListner.eventName);
     	that.instance.addEventListener(eventListner.eventName, eventListner.listener);
    });
	}, this.autoReconnectInterval);
}
WebSocketClient.prototype.on = function(eventName, listener) {
  console.log("WebSocketClient: applying listner: " + eventName);
  this.externalOn.push({eventName: eventName, listener: listener});
  this.instance.addEventListener(eventName, listener);
}
module.exports = WebSocketClient;