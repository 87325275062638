import React, { useState, useEffect } from 'react';
import EventEmitter from 'eventemitter2';
import WebSocketClient from './websocketclient';
import jwt_decode from "jwt-decode";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoginForm from './LoginForm';
import axios from 'axios';

if (!window.emitter) {
    window.emitter = new EventEmitter({ wildcard: true });
    window.emitter.on('**', function () {
        console.log(JSON.stringify({
            event: this.event,
            arguments: arguments,
        }))
    })
}
if (!window.wsc) {
    const wsUrl = 'ws' + window.location.protocol.substring(4) + '//' + window.location.hostname+(window.location.port ? ':' + window.location.port : '') + '/admin-ws';
    window.wsc = new WebSocketClient({emitter: window.emitter});
    window.wsc.open(wsUrl);
    window.wsc.on('message', function (mesg) {
        const data = JSON.parse(mesg.data)
        if (data.type === 'event') {
            window.emitter.emit('server.' + data.event, data.data);
        }
    });
}
window.axiosWorking = 0;
class axiosToApi {
    constructor() {
        const instance = axios.create({})
        // Insert Token.
        instance.interceptors.request.use(function (config) {
            const tokenToSet = window.jwtToken?window.jwtToken:null;
            if (tokenToSet)
                config.headers['Authorization'] = `Bearer ${tokenToSet}`;
            return config;
        });
        // set working.
        instance.interceptors.request.use(function (config) {
            window.axiosWorking++;
            window.emitter.emit('api.request.update', window.axiosWorking);
            return config;
        });
        // unset working
        instance.interceptors.response.use(function (response) {
            window.axiosWorking--;
            window.emitter.emit('api.request.update', window.axiosWorking);
            return response;
        }, function (error) {
            window.axiosWorking--;
            window.emitter.emit('api.request.update', window.axiosWorking);
            return Promise.reject(error);
        });
        return instance;
   }
}
window.axiosToApi = axiosToApi;

window.newJwtToken = jwtTokenIncomming => {
    window.jwtToken = jwtTokenIncomming;
    const current_state = window.getLogInState();
    try {
        window.payload = jwt_decode(jwtTokenIncomming);
        // Convert valid time to localtime -30s
        const refreshSec = window.payload.exp - window.payload.iat - 30;
        window.payload.refreshTime = Math.floor(Date.now() / 1000) + refreshSec
        if (current_state !== 2) window.wsc.authenticate(jwtTokenIncomming);
        window.setLogInState(2);
        if (window.jwtRefreshTimeout) clearTimeout(window.jwtRefreshTimeout);
        window.jwtRefreshTimeout = setTimeout(window.refreshJwtToken, (refreshSec * 1000));
        window.emitter.emit('jwttoken.new', window.payload);
    } catch (error) {
        window.jwtToken = '';
        window.payload = {};
        window.setLogInState(1);
        if (current_state !== 1) window.wsc.authenticate();
        if (window.jwtRefreshTimeout) clearTimeout(window.jwtRefreshTimeout);
        window.emitter.emit('jwttoken.new', window.payload);
    }
}
window.refreshJwtToken = () => {
    const axios = new window.axiosToApi();
    axios.post(
        '/api/refresh',
        {},
        { withCredentials: true } //force cookies
    ).then(res => {
        window.newJwtToken(res.data);
    }, e => {
        window.newJwtToken('');
    }); 
}
window.logout = () => {
    const axios = new window.axiosToApi();
    axios.post(
        '/api/logout',
        {},
        { withCredentials: true } //force cookies
    ).then(res => {
        window.newJwtToken('');
    }, e => {
        window.newJwtToken('');
    }); 
}
window.setInitialLoginState = loginState => {
    const tokenToSet = window.jwtToken?window.jwtToken:'';
    if (loginState === 0) {
        window.refreshJwtToken(tokenToSet)
    }
}

const Secure = props => {
    const [logInState, setLogInState] = useState(0);
    window.setLogInState = setLogInState;
    window.getLogInState = () => logInState
    useEffect(() => {
        if (logInState === 0) window.setInitialLoginState(0);
    }, [logInState])

    return (
        <>
            {logInState === 0 && <Backdrop open={true}><CircularProgress/></Backdrop>}
            {logInState === 1 && <LoginForm />}
            {logInState === 2 && props.children}
        </>
    );
}
export default Secure;