import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) =>
  createStyles({
    idle: {
        display: 'none',
    },
    loading: {
      position: 'relative',
      margin: 4,
      paddingTop: 4,
    },
    bottom: {
      color: theme.palette.info.main,
    },
    top: {
      color: theme.palette.info.contrastText,
      animationDuration: '650ms',
      position: 'absolute',
      left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
  }),
);

function AxiosCircularProgress(props) {
    const classes = useStyles();
    const action = props.action?props.action:'idle'
    return (
      <div className={classes[action]}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={16}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={16}
          thickness={4}
          {...props}
        />
      </div>
    );
}

const ApiWorkingIndicator = () => { 
    const [status, setStatus] = useState('idle');
    useEffect(() => {
        const listener = window.emitter.on('api.request.update', function(num_requests) {
            if (num_requests > 0) setStatus('loading');
            else setStatus('idle');
        }, {objectify: true});
        return () => {
            listener.off();
        };
    });
    
    return (
        <AxiosCircularProgress action={status}/>
    );
}
export default ApiWorkingIndicator
